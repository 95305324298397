import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className="section-padding">
      <h1>{`Chatbot`}</h1>
      <h2>{`Chatbot`}</h2>
      <h3>{`Chatbot`}</h3>
      <h2>{`Frequently Asked Questions`}</h2>
      <h3>{`Why Gatsby?`}</h3>
      <p>{`Gatsby delivers faster, more secure sites and apps from a variety of data
sources`}</p>
      <h3>{`Where do I start?`}</h3>
      <p>{`The documentation offers guides for all different skill levels, you can
find more info at the Gatsby's `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.org/docs/quick-start"
        }}>{`Quick Start page`}</a></p>
    </section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      